import { useTheme } from '@emotion/react';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  paper: {
    background: (props) => props.background,
  },
  menu: {
    '& :hover': {
      background: (props) => props.hoverBackground,
      color: (props) => props.hoverColor,
    },
  },
}));

const LinkMenu = ({ children }) => {
  const {
    colors: { frameBackground, frameBackgroundHighlight, text },
  } = useTheme();
  const { root, paper, menu } = useStyles({
    background: frameBackground,
    hoverBackground: frameBackgroundHighlight,
    hoverColor: text,
  });
  return (
    <div className={root}>
      <Paper className={paper}>
        <MenuList className={menu}>{children}</MenuList>
      </Paper>
    </div>
  );
};

LinkMenu.propTypes = {
  children: PropTypes.node,
};

export default LinkMenu;
