import {
  API_URL,
  API_VERSION,
  API_KEY,
  API_SECRET,
  ENDPOINT_PING,
  ENDPOINT_FRAMEWORK,
  ENDPOINT_FRAMEWORK_BULK,
  ENDPOINT_SCORE,
  ENDPOINT_SCORE_BULK,
  ENDPOINT_USAGE,
} from './constants';

const matlab = (endpoint) => {
  switch (endpoint) {
    case ENDPOINT_SCORE:
    case ENDPOINT_FRAMEWORK:
      return `function receptiviti_data = hitReceptivitiAPI(text_data)
    request = struct('request_id','req-1','content',text_data);
    api_key = '${API_KEY}';
    api_secret = '${API_SECRET}';
    options = weboptions('HeaderFields',{'Authorization',...
        ['Basic ' matlab.net.base64encode([api_key ':' api_secret])]});
    options.ContentType = 'json';
    options.RequestMethod = 'POST';
    URL = '${API_URL}${API_VERSION}${endpoint}';
    receptiviti_data = webwrite(URL,request,options);
end`;
    case ENDPOINT_SCORE_BULK:
    case ENDPOINT_FRAMEWORK_BULK:
      return `function receptiviti_data = hitReceptivitiAPIBulk(cell_array_of_text_data)
    [r,c] = size(cell_array_of_text_data);
    n = max(r,c);
    build_struct = @(text_data,id) struct('content',text_data,'request_id',strcat('req-',id));
    cell_array_of_structs = cellfun(@(text_data,id)build_struct(text_data,id),cell_array_of_text_data,cellstr(string(1:n)),'UniformOutput',false);
    api_key = '${API_KEY}';
    api_secret = '${API_SECRET}';
    options = weboptions('HeaderFields',{'Authorization',...
        ['Basic ' matlab.net.base64encode([api_key ':' api_secret])]});
    options.ContentType = 'json';
    options.RequestMethod = 'POST';
    URL = '${API_URL}${API_VERSION}${endpoint}';
    receptiviti_data = webwrite(URL,cell_array_of_structs,options);
end`;
    case ENDPOINT_PING:
      return `function receptiviti_data = hitReceptivitiAPIPing()
    api_key = '${API_KEY}';
    api_secret = '${API_SECRET}';
    options = weboptions('HeaderFields',{'Authorization',...
        ['Basic ' matlab.net.base64encode([api_key ':' api_secret])]});
    options.ContentType = 'json';
    options.RequestMethod = 'GET';
    URL = '${API_URL}${API_VERSION}${ENDPOINT_PING}';
    receptiviti_data = webwrite(URL,options);
end`;
    case ENDPOINT_USAGE:
      return `function receptiviti_data = hitReceptivitiAPIUsage()
    api_key = '${API_KEY}';
    api_secret = '${API_SECRET}';
    options = weboptions('HeaderFields',{'Authorization',...
        ['Basic ' matlab.net.base64encode([api_key ':' api_secret])]});
    options.ContentType = 'json';
    options.RequestMethod = 'GET';
    URL = '${API_URL}${API_VERSION}${ENDPOINT_USAGE}';
    receptiviti_data = webwrite(URL,options);
end`;
    default:
      return '';
  }
};

export default matlab;
