import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useRowStyles = makeStyles({
  row: {
    '& td': {
      background: 'white',
      fontSize: '15px',
    },
  },
  container: {
    '& th': {
      color: 'charcoal',
      fontSize: '16px',
      fontWeight: 700,
    },
  },
});

function Row({ row, depth, display }) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  if (!display) {
    return <></>;
  }

  let button = <div style={{ display: 'inline-block', width: '30px' }} />;
  let sub = <></>;
  if (row.rows && row.rows.length) {
    button = (
      <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </IconButton>
    );
    sub = (
      <>
        {row.rows.map((r, index) => (
          <Row key={index} row={r} depth={depth + 1} display={open} />
        ))}
      </>
    );
  }

  const category = row.category ? <code>{row.category}</code> : <></>;

  return (
    <React.Fragment>
      <TableRow className={classes.row}>
        <TableCell>
          <span style={{ marginLeft: depth * 24 + 'px' }}>
            {button}
            {row.score}
          </span>
        </TableCell>
        <TableCell>{category}</TableCell>
        <TableCell>{row.examples}</TableCell>
      </TableRow>
      {sub}
    </React.Fragment>
  );
}

export default function NestedScoreTable({ rows }) {
  const classes = useRowStyles();

  return (
    <TableContainer className={classes.container}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Measure</TableCell>
            <TableCell>Examples</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <Row key={index} row={row} depth={0} display={true} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

NestedScoreTable.propTypes = {
  rows: PropTypes.any.isRequired,
};
