import {
  API_URL,
  API_VERSION,
  API_KEY,
  API_SECRET,
  ENDPOINT_PING,
  ENDPOINT_FRAMEWORK,
  ENDPOINT_FRAMEWORK_BULK,
  ENDPOINT_SCORE,
  ENDPOINT_SCORE_BULK,
  ENDPOINT_USAGE,
} from './constants';

const curl = (endpoint) => {
  switch (endpoint) {
    case ENDPOINT_SCORE:
    case ENDPOINT_FRAMEWORK:
      return `curl --location --request \\
POST '${API_URL}${API_VERSION}${endpoint}' \\
-u ${API_KEY}:${API_SECRET} \\
--header 'Content-Type: application/json' \\
--data-raw '{
    "request_id": "req-1",
    "content": "Some thrilling content..."
}'
`;
    case ENDPOINT_SCORE_BULK:
    case ENDPOINT_FRAMEWORK_BULK:
      return `curl --location --request \\
POST '${API_URL}${API_VERSION}${endpoint}' \\
-u ${API_KEY}:${API_SECRET} \\
--header 'Content-Type: application/json' \\
--data-raw '[
    {
        "request_id": "req-1",
        "content": "Some thrilling content..."
    },
    {
        "request_id": "req-2",
        "content": "More thrilling content..."
    },
    {
        "request_id": "req-3",
        "content": "Even more thrilling content..."
    }
]'
`;
    case ENDPOINT_PING:
      return `curl --location --request \\
GET '${API_URL}${API_VERSION}${ENDPOINT_PING}' \\
-u ${API_KEY}:${API_SECRET}
`;
    case ENDPOINT_USAGE:
      return `curl --location --request \\
GET '${API_URL}${API_VERSION}${ENDPOINT_USAGE}' \\
-u ${API_KEY}:${API_SECRET}
`;
    case 'topLevelError':
      return `curl --location --request \\
POST '${API_URL}${API_VERSION}${ENDPOINT_SCORE}' \\
-u <NOT_YOUR_API_KEY>:<NOT_YOUR_API_SECRET> \\
--header 'Content-Type: application/json' \\
--data-raw '{
    "request_id": "req-1",
    "content": "Some thrilling content..."
}'
`;
    case 'nestedError':
      return `curl --location --request \\
POST '${API_URL}${API_VERSION}${ENDPOINT_SCORE}' \\
-u ${API_KEY}:${API_SECRET} \\
--header 'Content-Type: application/json' \\
--data-raw '{
    "request_id": "req-1",
    "content": ""
}'
`;
    default:
      return '';
  }
};

export default curl;
