const baseTheme = {
  fonts: {
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
};

const lightTheme = {
  ...baseTheme,
  colors: {
    background: '#fff',
    heading: '#000',
    text: '#3b454e',
    secondaryText: '#54636f',
    preFormattedText: 'rgb(245, 247, 249)',
    highlightText: '#d8131d',
    frameBackground: '#f2f2f2',
    frameBackgroundHighlight: '#e5e5e5',
  },
};

const darkTheme = {
  ...baseTheme,
  colors: {
    background: '#171923',
    heading: '#fff',
    text: '#fff',
    secondaryText: '#d9d9d9',
    preFormattedText: '#000',
    highlightText: '#fc6f6d',
    frameBackground: '#1e222f',
    frameBackgroundHighlight: '#262b3b',
  },
};

export { lightTheme, darkTheme };
