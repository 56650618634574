const liwcTableRows = [
  {
    "score": "Summary Language Variables",
    "rows": [
      {
        "score": "Analytical thinking",
        "category": "analytical_thinking",
        "examples": ""
      },
      {
        "score": "Clout",
        "category": "clout",
        "examples": ""
      },
      {
        "score": "Authentic",
        "category": "authentic",
        "examples": ""
      },
      {
        "score": "Emotional tone",
        "category": "emotional_tone",
        "examples": ""
      },
      {
        "score": "Dictionary words",
        "category": "dictionary_words",
        "examples": ""
      }
    ]
  },
  {
    "score": "Linguistic Dimensions",
    "rows": [
      {
        "score": "Total function words",
        "category": "function_words",
        "examples": "it, to, no, very ",
        "rows": [
          {
            "score": "Total pronouns",
            "category": "pronouns",
            "examples": "I, them, itself",
            "rows": [
              {
                "score": "Personal pronouns",
                "category": "personal_pronouns",
                "examples": "I, them, her",
                "rows": [
                  {
                    "score": "1st person singular",
                    "category": "i",
                    "examples": "I, me, mine"
                  },
                  {
                    "score": "1st person plural",
                    "category": "we",
                    "examples": "we, us, our"
                  },
                  {
                    "score": "2nd person",
                    "category": "you",
                    "examples": "you, your, thou"
                  },
                  {
                    "score": "3rd person singular",
                    "category": "she_he",
                    "examples": "she, her, him"
                  },
                  {
                    "score": "3rd person plural",
                    "category": "they",
                    "examples": "they, their, they’d"
                  }
                ]
              },
              {
                "score": "Impersonal pronouns",
                "category": "impersonal_pronouns",
                "examples": "it, it’s, those"
              }
            ]
          },
          {
            "score": "Articles",
            "category": "articles",
            "examples": "a, an, the"
          },
          {
            "score": "Prepositions",
            "category": "prepositions",
            "examples": "to, with, above"
          },
          {
            "score": "Auxiliary verbs",
            "category": "auxiliary_verbs",
            "examples": "am, will, have"
          },
          {
            "score": "Common Adverbs",
            "category": "adverbs",
            "examples": "very, really"
          },
          {
            "score": "Conjunctions",
            "category": "conjunctions",
            "examples": "and, but, whereas"
          },
          {
            "score": "Negations",
            "category": "negations",
            "examples": "no, not, never"
          }
        ]
      }
    ]
  },
  {
    "score": "Other Grammar",
    "rows": [
      {
        "score": "Common verbs",
        "category": "verbs",
        "examples": "eat, come, carry"
      },
      {
        "score": "Common adjectives",
        "category": "adjectives",
        "examples": "free, happy, long"
      },
      {
        "score": "Comparisons",
        "category": "comparisons",
        "examples": "greater, best, after"
      },
      {
        "score": "Interrogatives",
        "category": "interrogatives",
        "examples": "how, when, what"
      },
      {
        "score": "Numbers",
        "category": "numbers",
        "examples": "second, thousand"
      },
      {
        "score": "Quantifiers",
        "category": "quantifiers",
        "examples": "few, many, much"
      }
    ]
  },
  {
    "score": "Psychological Processes",
    "rows": [
      {
        "score": "Affective processes",
        "category": "affective_processes",
        "examples": "happy, cried",
        "rows": [
          {
            "score": "Positive emotion",
            "category": "positive_emotion_words",
            "examples": "love, nice, sweet"
          },
          {
            "score": "Negative emotion",
            "category": "negative_emotion_words",
            "examples": "hurt, ugly, nasty",
            "rows": [
              {
                "score": "Anxiety",
                "category": "anxiety_words",
                "examples": "worried, fearful"
              },
              {
                "score": "Anger",
                "category": "anger_words",
                "examples": "hate, kill, annoyed"
              },
              {
                "score": "Sadness",
                "category": "sad_words",
                "examples": "crying, grief, sad"
              }
            ]
          }
        ]
      },
      {
        "score": "Social processes",
        "category": "social_processes",
        "examples": "mate, talk, they",
        "rows": [
          {
            "score": "Family",
            "category": "family",
            "examples": "daughter, dad, aunt"
          },
          {
            "score": "Friends",
            "category": "friends",
            "examples": "buddy, neighbor"
          },
          {
            "score": "Female references",
            "category": "female",
            "examples": "girl, her, mom"
          },
          {
            "score": "Male references",
            "category": "male",
            "examples": "boy, his, dad"
          }
        ]
      },
      {
        "score": "Cognitive processes",
        "category": "cognitive_processes",
        "examples": "cause, know, ought",
        "rows": [
          {
            "score": "Insight",
            "category": "insight",
            "examples": "think, know"
          },
          {
            "score": "Causation",
            "category": "causation",
            "examples": "because, effect"
          },
          {
            "score": "Discrepancy",
            "category": "discrepancies",
            "examples": "should, would"
          },
          {
            "score": "Tentative",
            "category": "tentative",
            "examples": "maybe, perhaps"
          },
          {
            "score": "Certainty",
            "category": "certainty",
            "examples": "always, never"
          },
          {
            "score": "Differentiation",
            "category": "differentiation",
            "examples": "hasn't, but, else"
          }
        ]
      },
      {
        "score": "Perceptual processes",
        "category": "perceptual_processes",
        "examples": "look, heard, feeling",
        "rows": [
          {
            "score": "See",
            "category": "see",
            "examples": "view, saw, seen"
          },
          {
            "score": "Hear",
            "category": "hear",
            "examples": "listen, hearing"
          },
          {
            "score": "Feel",
            "category": "feel",
            "examples": "feels, touch"
          }
        ]
      },
      {
        "score": "Biological processes",
        "category": "biological_processes",
        "examples": "eat, blood, pain",
        "rows": [
          {
            "score": "Body",
            "category": "body",
            "examples": "cheek, hands, spit"
          },
          {
            "score": "Health",
            "category": "health",
            "examples": "clinic, flu, pill"
          },
          {
            "score": "Sexual",
            "category": "sexual",
            "examples": "horny, love, incest"
          },
          {
            "score": "Ingestion",
            "category": "ingestion",
            "examples": "dish, eat, pizza"
          }
        ]
      },
      {
        "score": "Drives",
        "category": "drives",
        "examples": "",
        "rows": [
          {
            "score": "Affiliation",
            "category": "affiliation",
            "examples": "ally, friend, social"
          },
          {
            "score": "Achievement",
            "category": "achievement",
            "examples": "win, success, better"
          },
          {
            "score": "Power",
            "category": "power",
            "examples": "superior, bully"
          },
          {
            "score": "Reward",
            "category": "reward",
            "examples": "take, prize, benefit"
          },
          {
            "score": "Risk",
            "category": "risk",
            "examples": "danger, doubt"
          }
        ]
      },
      {
        "score": "Time orientations",
        "category": "time_orientation",
        "examples": "",
        "rows": [
          {
            "score": "Past focus",
            "category": "focus_past",
            "examples": "ago, did, talked"
          },
          {
            "score": "Present focus",
            "category": "focus_present",
            "examples": "today, is, now"
          },
          {
            "score": "Future focus",
            "category": "focus_future",
            "examples": "may, will, soon"
          }
        ]
      },
      {
        "score": "Relativity",
        "category": "relativity",
        "examples": "area, bend, exit",
        "rows": [
          {
            "score": "Motion",
            "category": "motion",
            "examples": "arrive, car, go"
          },
          {
            "score": "Space",
            "category": "space",
            "examples": "down, in, thin"
          },
          {
            "score": "Time",
            "category": "time",
            "examples": "end, until, season"
          }
        ]
      },
      {
        "score": "Personal concerns",
        "category": "personal_concerns",
        "examples": "",
        "rows": [
          {
            "score": "Work",
            "category": "work",
            "examples": "job, majors, xerox"
          },
          {
            "score": "Leisure",
            "category": "leisure",
            "examples": "cook, chat, movie"
          },
          {
            "score": "Home",
            "category": "home",
            "examples": "kitchen, landlord"
          },
          {
            "score": "Money",
            "category": "money",
            "examples": "audit, cash, owe"
          },
          {
            "score": "Religion",
            "category": "religion",
            "examples": "altar, church"
          },
          {
            "score": "Death",
            "category": "death",
            "examples": "bury, coffin, kill"
          }
        ]
      },
      {
        "score": "Informal language",
        "category": "informal_language",
        "examples": "",
        "rows":[
          {
            "score": "Swear words",
            "category": "swear_words",
            "examples": "fuck, damn, shit"
          },
          {
            "score": "Netspeak",
            "category": "netspeak",
            "examples": "btw, lol, thx"
          },
          {
            "score": "Assent",
            "category": "assent",
            "examples": "agree, OK, yes"
          },
          {
            "score": "Nonfluencies",
            "category": "nonfluencies",
            "examples": "er, hm, umm"
          },
          {
            "score": "Fillers",
            "category": "filler_words",
            "examples": "Imean, youknow"
          }
        ]
      }
    ]
  }
];

export default liwcTableRows;
