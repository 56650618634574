import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Loadable from 'react-loadable';
import Link from './link';

import { makeStyles } from '@material-ui/core/styles';
import config from '../../config.js';
import { DarkModeSwitch } from './DarkModeSwitch';
import LoadingProvider from './mdxComponents/loading';

const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];

if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({
    name: `${config.header.search.indexName}`,
    title: `Results`,
    hitComp: `PageHit`,
  });
}

import { useTheme } from '@emotion/react';
import Sidebar from './sidebar';

const SearchBar = Loadable({
  loader: () => import('./search/index'),
  loading: LoadingProvider,
});

function myFunction() {
  var x = document.getElementById('navbar');

  if (x.className === 'topnav') {
    x.className += ' responsive';
  } else {
    x.className = 'topnav';
  }
}

const StyledBgDiv = styled('div')`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const StyledHeader = styled('div')`
  background-color: ${(props) => props.theme.colors.frameBackground};
`;

const useStyles = makeStyles(() => ({
  linkWrapper: {
    '& a': {
      color: (props) => props.color,
      transition: '0.15s',
    },
    '& :hover': {
      color: (props) => props.hoverColor,
    },
  },
}));

const Header = ({ location, isDarkThemeActive, toggleActiveTheme }) => (
  <StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            headerLinks {
              link
              text
            }
          }
        }
      }
    `}
    render={() => {
      const logoImg = require(`./images/receptiviti-${
        isDarkThemeActive ? 'dark' : 'light'
      }-theme.png`);
      const linkedin = require('./images/linkedin-small-cropped.svg');
      const finalLogoLink = '/';

      const {
        colors: { highlightText, text },
      } = useTheme();
      const { linkWrapper } = useStyles({ color: highlightText, hoverColor: text });

      return (
        <StyledHeader className="navBarWrapper">
          <nav className={'navBarDefault'}>
            <div className="navBarHeader">
              <Link to={finalLogoLink} className={'navBarBrand'}>
                <img
                  className={'img-responsive displayInline'}
                  src={logoImg.default}
                  alt={'logo'}
                />
              </Link>
            </div>
            {isSearchEnabled ? (
              <div className={'searchWrapper hiddenMobile'}>
                <SearchBar collapse={true} indices={searchIndices} color={highlightText} />
              </div>
            ) : null}
            <div className={`navLinkWrapper ${linkWrapper}`}>
              <Link className="navLink" target="_blank" to="https://receptiviti.com">
                Receptiviti.com
              </Link>
              <Link className="navLink" target="_blank" to="https://dashboard.receptiviti.com">
                API Dashboard
              </Link>
              <Link className="navLink" target="_blank" to="https://www.receptiviti.com/research">
                Research Library
              </Link>
              <Link
                className="navLink"
                target="_blank"
                to="https://www.receptiviti.com/case-studies"
              >
                Case Studies
              </Link>
            </div>
            <div id="navbar" className={'topnav'} style={{ borderColor: text }}>
              <div className={'visibleMobile'}>
                <Sidebar location={location} />
                <hr />
              </div>
              <ul className={'navBarUL navBarNav'}>
                {config.header.social ? (
                  <li className="hiddenMobile">
                    <a
                      href="https://ca.linkedin.com/company/receptiviti"
                      target="_blank"
                      rel="noopener"
                    >
                      <div className="linkedinBtn">
                        <img src={linkedin.default} alt="LinkedIn" />
                      </div>
                    </a>
                  </li>
                ) : null}
                <li>
                  <DarkModeSwitch
                    isDarkThemeActive={isDarkThemeActive}
                    toggleActiveTheme={toggleActiveTheme}
                  />
                </li>
              </ul>
            </div>
          </nav>
          <StyledBgDiv isDarkThemeActive={isDarkThemeActive}>
            <div className={'navBarDefault removePadd'}>
              <span
                onClick={myFunction}
                className={'navBarToggle'}
                onKeyDown={myFunction}
                role="button"
                tabIndex={0}
              >
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
              </span>
            </div>
            {isSearchEnabled ? null : null}
          </StyledBgDiv>
        </StyledHeader>
      );
    }}
  />
);

export default Header;
