import React from 'react';
import { Link } from 'gatsby';
import isAbsoluteUrl from 'is-absolute-url';

const AnchorTag = ({ children: label, href, ...props }) => {
  if (!label || !href) {
    return <></>;
  }

  if (isAbsoluteUrl(href)) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    );
  }

  return (
    <Link to={href} {...props}>
      {label}
    </Link>
  );
};

export default AnchorTag;
