import { graphql, StaticQuery } from 'gatsby';
import config from '../../config';
import { ListItem, Sidebar } from './styles/Sidebar';

const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
              }
              tableOfContents
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      if (!allMdx.edges?.length) {
        return null;
      }
      return (
        <Sidebar>
          <ul className={'rightSideBarUL'}>
            <li className={'rightSideTitle'}>CONTENTS</li>
            {allMdx.edges.map((item) => {
              if (
                !item ||
                (item.node.fields.slug !== location.pathname &&
                  config.gatsby.pathPrefix + item.node.fields.slug !== location.pathname)
              ) {
                return null;
              }
              return item.node.tableOfContents.items?.map((innerItem, index) => {
                const itemId = innerItem.title
                  ? innerItem.title.replace(/\s+/g, '').toLowerCase()
                  : '#';
                return (
                  <ListItem key={index} to={`#${itemId}`} level={1}>
                    {innerItem.title}
                  </ListItem>
                );
              });
            })}
          </ul>
        </Sidebar>
      );
    }}
  />
);

export default SidebarLayout;
