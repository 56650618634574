import {
  LANGUAGE_CURL,
  LANGUAGE_JAVASCRIPT,
  LANGUAGE_MATLAB,
  LANGUAGE_PYTHON,
  LANGUAGE_R
} from './constants';
import curlExample from './curl';
import javascriptExample from './javascript';
import matlabExample from './matlab';
import pythonExample from './python';
import rExample from './r';

const getCodeExample = (language, endpoint) => {
  switch (language) {
    case LANGUAGE_CURL:
      return curlExample(endpoint);
    case LANGUAGE_JAVASCRIPT:
      return javascriptExample(endpoint);
    case LANGUAGE_MATLAB:
      return matlabExample(endpoint);
    case LANGUAGE_PYTHON:
      return pythonExample(endpoint);
    case LANGUAGE_R:
      return rExample(endpoint);
    default:
      return '';
  }
};

export default getCodeExample;
