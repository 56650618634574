import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import getCodeExample from './example';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

const CodeCopy = ({ endpoint, language, ...other }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const example = getCodeExample(language, endpoint);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <CopyToClipboard text={example} onCopy={handleClick}>
        <Fab size="small" aria-label="Copy Code" className={classes.root}>
          <FileCopyIcon/>
        </Fab>
      </CopyToClipboard>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Code copied!"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

CodeCopy.propTypes = {
  endpoint: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
};

export default CodeCopy;
