import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Tree from './tree';

const Sidebar = styled('aside')`
  width: 100%;
  background: ${(props) => props.theme.colors.frameBackground};
  color: #fff;
  height: 100vh;
  overflow: auto;
  position: fixed;
  padding-left: 0px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: sticky;
  top: 0;
  padding-right: 0;
  // -webkit-box-shadow: -1px 0px 4px 1px rgba(175, 158, 232, 0.4);

  .sideBarUL li a span {
    transition: color 0.15s;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
    /* position: relative; */
    height: 100vh;
  }

  @media (min-width: 767px) and (max-width: 1023px) {
    padding-left: 0;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0px;
    height: auto;
  }
`;

const SidebarLayout = () => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
                title
              }
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      return (
        <Sidebar>
          <ul className="sideBarUL">
            <Tree edges={allMdx.edges} />
          </ul>
        </Sidebar>
      );
    }}
  />
);

export default SidebarLayout;
