import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import AnchorTag from './AnchorTag';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  anchor: {
    display: 'inline-block',
    padding: '6px 24px',
    width: '100%',
  },
}));

const LinkMenuItem = ({ href, label, ...props }) => {
  const { root, anchor } = useStyles();
  return (
    <MenuItem className={root}>
      <AnchorTag className={anchor} href={href} {...props}>
        {label}
      </AnchorTag>
    </MenuItem>
  );
};

LinkMenuItem.propTypes = {
  href: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
};

export default LinkMenuItem;
