const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://docs.receptiviti.com',
    trailingSlash: false,
  },
  header: {
    logoLink: 'https://docs.receptiviti.com',
    title: '',
    githubUrl: 'n/a',
    helpUrl: '',
    tweetText: '',
    social: true,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'index_receptiviti',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [],
    forcedNavTree: {
      items: [
        {
          label: 'the-receptiviti-api',
          title: 'The Receptiviti API - Documentation Home',
          url: '/the-receptiviti-api',
          items: [
            {
              label: 'getting-started',
              title: 'Getting Started',
              url: '/the-receptiviti-api/getting-started',
              items: [],
            },
            {
              label: 'normed-vs-dictionary-counted-measures',
              title: 'Normed vs. Dictionary Measures',
              url: '/the-receptiviti-api/normed-vs-dictionary-counted-measures',
              items: [],
            },
            {
              label: 'preparing-your-data',
              title: 'Preparing Your Data',
              url: '/the-receptiviti-api/preparing-your-data',
              items: [],
            },
          ],
        },
        {
          label: 'frameworks',
          title: 'Frameworks',
          url: '/frameworks',
          items: [
            {
              label: '1-personality-big-5',
              title: 'Personality - Big 5',
              url: '/frameworks/personality-big-5',
              items: [],
            },
            {
              label: 'personality-disc',
              title: 'Personality - DISC',
              url: '/frameworks/personality-disc',
              items: [],
            },
            {
              label: 'emotions',
              title: 'Emotions',
              url: '/frameworks/emotions',
              items: [],
            },
            {
              label: 'cognition',
              title: 'Cognition',
              url: '/frameworks/cognition',
              items: [],
            },
            {
              label: 'drives',
              title: 'Drives',
              url: '/frameworks/drives',
              items: [],
            },
            /*
            {
              "label": "interpersonal",
              "title": "Interpersonal",
              "url": "/frameworks/interpersonal",
              "items": []
            },
            */
            {
              label: 'liwc',
              title: 'LIWC',
              url: '/frameworks/liwc',
              items: [],
            },
            {
              label: 'liwc-extension',
              title: 'LIWC Extension',
              url: '/frameworks/liwc-extension',
              items: [],
            },
            {
              label: 'social-dynamics',
              title: 'Social Dynamics',
              url: '/frameworks/social-dynamics',
              items: [],
            },
            {
              label: 'temporal-and-orientation',
              title: 'Temporal and Orientation',
              url: '/frameworks/temporal-and-orientation',
              items: [],
            },
            {
              label: 'toxicity',
              title: 'Toxicity',
              url: '/frameworks/toxicity',
              items: [],
            },
            {
              label: 'needs-and-values',
              title: 'Needs and Values',
              url: '/frameworks/needs-and-values',
              items: [],
            },
            {
              label: 'interpersonal-circumplex',
              title: 'Interpersonal Circumplex',
              url: '/frameworks/interpersonal-circumplex',
              items: [],
            },
            {
              label: 'fast-and-slow-thinking-index',
              title: 'Fast and Slow Thinking Index',
              url: '/frameworks/fast-and-slow-thinking-index',
              items: [],
            },
          ],
        },
        /*
        {
          "label": "taxonomies",
          "title": "Taxonomies",
          "url": "/taxonomies",
          "items": [
            {
              "label": "anti-vaccination",
              "title": "Anti-Vaccination",
              "url": "/taxonomies/anti-vaccination",
              "items": []
            },
            {
              "label": "conversation-topics",
              "title": "Conversation Topics",
              "url": "/taxonomies/conversation-topics",
              "items": []
            },
            {
              "label": "return-to-work",
              "title": "Return to Work",
              "url": "/taxonomies/return-to-work",
              "items": []
            }
          ]
        },
        */
        /*
        {
          "label": "arc-of-narrative",
          "title": "Arc of Narrative",
          "url": "/arc-of-narrative",
          "items": []
        },
        */
        {
          label: 'api-reference',
          title: 'API Reference',
          url: '/api-reference',
          items: [
            {
              label: 'ping',
              title: 'Ping',
              url: '/api-reference/ping',
              items: [],
            },
            {
              label: 'framework-single',
              title: 'Framework Single',
              url: '/api-reference/framework-single',
              items: [],
            },
            {
              label: 'framework-bulk',
              title: 'Framework Bulk',
              url: '/api-reference/framework-bulk',
              items: [],
            },
            {
              label: 'score-single',
              title: 'Score Single',
              url: '/api-reference/score-single',
              items: [],
            },
            {
              label: 'score-bulk',
              title: 'Score Bulk',
              url: '/api-reference/score-bulk',
              items: [],
            },
            {
              label: 'usage',
              title: 'Usage',
              url: '/api-reference/usage',
              items: [],
            },
            {
              label: 'errors',
              title: 'Errors',
              url: '/api-reference/errors',
              items: [],
            },
          ],
        },
        {
          label: 'developer packages',
          title: 'Developer Packages',
          url: '/developer-packages',
          items: [],
        },
        {
          label: 'faq',
          title: 'FAQ',
          url: '/faq',
          items: [],
        },
      ],
    },
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
    ],
    links: [{ text: '', link: '' }],
    frontline: false,
    ignoreIndex: true,
    title: '',
  },
  siteMetadata: {
    title: 'Receptiviti API Documentation',
    description: 'Documentation for the Receptiviti API.',
    ogImage: null,
    docsLocation: '',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
