import styled from '@emotion/styled';

export const StyledHeading = styled('h1')`
  font-size: 28px;
  line-height: 1.5;
  font-weight: 500;
  border-left: 2px solid ${(props) => props.theme.colors.highlightText};
  padding: 0 16px;
  flex: 1;
  margin-top: 0;
  padding-top: 0;
  color: ${(props) => props.theme.colors.heading};
`;

export const StyledMainWrapper = styled.div`
  max-width: 900px;
  color: ${(props) => props.theme.colors.text};

  ul,
  ol {
    -webkit-padding-start: 40px;
    -moz-padding-start: 40px;
    -o-padding-start: 40px;
    margin: 24px 0px;

    li {
      font-size: 16px;
      line-height: 1.8;
      font-weight: 400;
    }
  }

  .MuiList-root {
    padding: 0px;
  }

  a {
    transition: color 0.15s;
    color: ${(props) => props.theme.colors.highlightText};
  }

  blockquote {
    background: ${(props) => props.theme.colors.frameBackground};
    color: ${(props) => props.theme.colors.text};
    margin: 20px 0px;
    padding: 12px 16px;
    border-left: 4px solid ${(props) => props.theme.colors.highlightText};
  }
  blockquote p,
  blockquote .paragraph {
    margin: 0px 0px;
  }

  table tr th,
  table tr td {
    border: 1px solid ${(props) => props.theme.colors.frameBackground};
  }
  table tr th {
    background: ${(props) => props.theme.colors.frameBackground};
    color: ${(props) => props.theme.colors.text};
  }

  code {
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 0.9375em;
    color: ${(props) => props.theme.colors.text};

    background: ${(props) => props.theme.colors.frameBackgroundHighlight};
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;
