import {
  API_URL,
  API_VERSION,
  API_KEY,
  API_SECRET,
  ENDPOINT_PING,
  ENDPOINT_FRAMEWORK,
  ENDPOINT_FRAMEWORK_BULK,
  ENDPOINT_SCORE,
  ENDPOINT_SCORE_BULK,
  ENDPOINT_USAGE,
} from './constants';

const python = (endpoint) => {
  switch (endpoint) {
    case ENDPOINT_SCORE:
    case ENDPOINT_FRAMEWORK:
      return `import json
import requests

url = '${API_URL}${API_VERSION}${endpoint}'
api_key = '${API_KEY}'
api_secret = '${API_SECRET}'
data = json.dumps({
  'request_id': 'req-1',
  'content': 'Some thrilling content...'
})
resp = requests.post(url, auth=(api_key, api_secret), data=data)
print(resp.json())
`;
    case ENDPOINT_SCORE_BULK:
    case ENDPOINT_FRAMEWORK_BULK:
      return `import json
import requests

url = '${API_URL}${API_VERSION}${endpoint}'
api_key = '${API_KEY}'
api_secret = '${API_SECRET}'
data = json.dumps([
  {
    'request_id': 'req-1',
    'content': 'Some thrilling content...'
  },
  {
    'request_id': 'req-2',
    'content': 'More thrilling content...'
  },
  {
    'request_id': 'req-3',
    'content': 'Even more thrilling content...'
  }
])
resp = requests.post(url, auth=(api_key, api_secret), data=data)
print(resp.json())
`;
    case ENDPOINT_PING:
      return `import json
import requests

url = '${API_URL}${API_VERSION}${ENDPOINT_PING}'
api_key = '${API_KEY}'
api_secret = '${API_SECRET}'
resp = requests.get(url, auth=(api_key, api_secret))
print(resp.json())
`;
    case ENDPOINT_USAGE:
      return `import json
import requests

url = '${API_URL}${API_VERSION}${ENDPOINT_USAGE}'
api_key = '${API_KEY}'
api_secret = '${API_SECRET}'
resp = requests.get(url, auth=(api_key, api_secret))
print(resp.json())
`;
    default:
      return '';
  }
};

export default python;
