import {
  API_URL,
  API_VERSION,
  API_KEY,
  API_SECRET,
  ENDPOINT_PING,
  ENDPOINT_FRAMEWORK,
  ENDPOINT_FRAMEWORK_BULK,
  ENDPOINT_SCORE,
  ENDPOINT_SCORE_BULK,
  ENDPOINT_USAGE,
} from './constants';

const javascript = (endpoint) => {
  switch (endpoint) {
    case ENDPOINT_SCORE:
    case ENDPOINT_FRAMEWORK:
      return `// This example uses Axios: https://github.com/axios/axios
import axios from 'axios';
// Or if you use 'require':
// const axios = require('axios');

const url = '${API_URL}${API_VERSION}${endpoint}';
const data = {
  request_id: 'req-1',
  content: 'Some thrilling content...'
};

// If you prefer the promise approach:
axios.post(url, data, {
    auth: {
      username: '${API_KEY}',
      password: '${API_SECRET}'
    }
  })
  .then((response) => {
    console.log(response.data);
  })
  .catch((error) => {
    console.log(error);
  });

// If you prefer the async/await approach:
async function score(data) {
  try {
    const response = await axios.post(url, data, {
        auth: {
          username: '${API_KEY}',
          password: '${API_SECRET}'
        }
      });
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}
score(data);
`;
    case ENDPOINT_SCORE_BULK:
    case ENDPOINT_FRAMEWORK_BULK:
      return `// This example uses Axios: https://github.com/axios/axios
import axios from 'axios';
// Or if you use 'require':
// const axios = require('axios');

const url = '${API_URL}${API_VERSION}${endpoint}';
const data = [
  {
    request_id: 'req-1',
    content: 'Some thrilling content...'
  },
  {
    request_id: 'req-2',
    content: 'More thrilling content...'
  },
  {
    request_id: 'req-3',
    content: 'Even more thrilling content...'
  }
];

// If you prefer the promise approach:
axios.post(url, data, {
    auth: {
      username: '${API_KEY}',
      password: '${API_SECRET}'
    }
  })
  .then((response) => {
    console.log(response.data);
  })
  .catch((error) => {
    console.log(error);
  });

// If you prefer the async/await approach:
async function score(data) {
  try {
    const response = await axios.post(url, data, {
        auth: {
          username: '${API_KEY}',
          password: '${API_SECRET}'
        }
      });
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}
score(data);
`;
    case ENDPOINT_PING:
      return `// This example uses Axios: https://github.com/axios/axios
import axios from 'axios';
// Or if you use 'require':
// const axios = require('axios');

const url = '${API_URL}${API_VERSION}${ENDPOINT_PING}';

// If you prefer the promise approach:
axios.get(url, {
    auth: {
      username: '${API_KEY}',
      password: '${API_SECRET}'
    }
  })
  .then((response) => {
    console.log(response.data);
  })
  .catch((error) => {
    console.log(error);
  });

// If you prefer the async/await approach:
async function ping() {
  try {
    const response = await axios.get(url, {
        auth: {
          username: '${API_KEY}',
          password: '${API_SECRET}'
        }
      });
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}
ping();
`;
    case ENDPOINT_USAGE:
      return `// This example uses Axios: https://github.com/axios/axios
import axios from 'axios';
// Or if you use 'require':
// const axios = require('axios');

const url = '${API_URL}${API_VERSION}${ENDPOINT_USAGE}';

// If you prefer the promise approach:
axios.get(url, {
    auth: {
      username: '${API_KEY}',
      password: '${API_SECRET}'
    }
  })
  .then((response) => {
    console.log(response.data);
  })
  .catch((error) => {
    console.log(error);
  });

// If you prefer the async/await approach:
async function usage() {
  try {
    const response = await axios.get(url, {
        auth: {
          username: '${API_KEY}',
          password: '${API_SECRET}'
        }
      });
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}
usage();
`;
    default:
      return '';
  }
};

export default javascript;
