import {
  API_URL,
  API_VERSION,
  API_KEY,
  API_SECRET,
  ENDPOINT_PING,
  ENDPOINT_FRAMEWORK,
  ENDPOINT_FRAMEWORK_BULK,
  ENDPOINT_SCORE,
  ENDPOINT_SCORE_BULK,
  ENDPOINT_USAGE,
} from './constants';

const r = (endpoint) => {
  switch (endpoint) {
    case ENDPOINT_SCORE:
    case ENDPOINT_FRAMEWORK:
      return `library(httr)
library(jsonlite)
api_key = '${API_KEY}'
api_secret = '${API_SECRET}'
url = '${API_URL}${API_VERSION}${endpoint}'
response = POST(url,
  authenticate(api_key, api_secret),
  body=list(
    request_id='req-1',
    content='Some thrilling content...'
  ),
  add_headers('Content-Type'='application/json'),
  encode='json')
scores = content(response, as='parsed')
str(scores)
`;
    case ENDPOINT_SCORE_BULK:
    case ENDPOINT_FRAMEWORK_BULK:
      return `library(httr)
library(jsonlite)
api_key = '${API_KEY}'
api_secret = '${API_SECRET}'
url = '${API_URL}${API_VERSION}${endpoint}'
response = POST(url,
  authenticate(api_key, api_secret),
  body=list(
    list(
      request_id='req-1',
      content='Some thrilling content...'
    ),
    list(
      request_id='req-2',
      content='More thrilling content...'
    ),
    list(
      request_id='req-3',
      content='Even more thrilling content...'
    )
  ),
  add_headers('Content-Type'='application/json'),
  encode='json')
scores = content(response, as='parsed')
str(scores)
`;
    case ENDPOINT_PING:
      return `library(httr)
library(jsonlite)
api_key = '${API_KEY}'
api_secret = '${API_SECRET}'
url = '${API_URL}${API_VERSION}${ENDPOINT_PING}'
response = GET(url,
  authenticate(api_key, api_secret))
pong = content(response, as='parsed')
str(pong)
`;
    case ENDPOINT_USAGE:
      return `library(httr)
library(jsonlite)
api_key = '${API_KEY}'
api_secret = '${API_SECRET}'
url = '${API_URL}${API_VERSION}${ENDPOINT_USAGE}'
response = GET(url,
  authenticate(api_key, api_secret))
usage = content(response, as='parsed')
str(usage)
`;
    default:
      return '';
  }
};

export default r;
