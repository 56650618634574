export const API_URL = 'https://api.receptiviti.com';
export const API_VERSION = '/v1';
export const API_KEY = '<YOUR_API_KEY>';
export const API_SECRET = '<YOUR_API_SECRET>';

export const ENDPOINT_PING = '/ping';
export const ENDPOINT_FRAMEWORK = '/framework';
export const ENDPOINT_FRAMEWORK_BULK = '/framework/bulk';
export const ENDPOINT_SCORE = '/score';
export const ENDPOINT_SCORE_BULK = '/score/bulk';
export const ENDPOINT_USAGE = '/usage';

export const LANGUAGE_CURL = 'curl';
export const LANGUAGE_JAVASCRIPT = 'javascript';
export const LANGUAGE_MATLAB = 'matlab';
export const LANGUAGE_PYTHON = 'python';
export const LANGUAGE_R = 'r';

// Provides a fixed ordering of the languages.
export const languages = [
  LANGUAGE_CURL,
  LANGUAGE_PYTHON,
  LANGUAGE_R,
  LANGUAGE_JAVASCRIPT,
  LANGUAGE_MATLAB,
];

// For use in tab names, etc.
export const languageLabels = {
  [LANGUAGE_CURL]: 'Curl',
  [LANGUAGE_PYTHON]: 'Python',
  [LANGUAGE_R]: 'R',
  [LANGUAGE_JAVASCRIPT]: 'JavaScript',
  [LANGUAGE_MATLAB]: 'MATLAB',
};

// For use in syntax highlighting.
export const languageHighlights = {
  [LANGUAGE_CURL]: 'bash',
  [LANGUAGE_PYTHON]: LANGUAGE_PYTHON,
  [LANGUAGE_R]: LANGUAGE_R,
  [LANGUAGE_JAVASCRIPT]: LANGUAGE_JAVASCRIPT,
  [LANGUAGE_MATLAB]: LANGUAGE_MATLAB,
};
