import React from 'react';
import PropTypes from 'prop-types';
import Highlight, {defaultProps} from 'prism-react-renderer';
import vsDark from 'prism-react-renderer/themes/vsDark';
import getCodeExample from './example';
import { languageHighlights } from './constants';

const CodeExample = ({ endpoint, language, ...other }) => {
  const className = 'language-' + language;
  const example = getCodeExample(language, endpoint);
  const highlight = languageHighlights[language];

  return (
    <Highlight {...defaultProps} theme={vsDark} code={example} language={highlight}>
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={className} style={{...style, padding: '20px'}}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({line, key: i})}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({token, key})} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

CodeExample.propTypes = {
  endpoint: PropTypes.any.isRequired,
  language: PropTypes.any.isRequired,
};

export default CodeExample;
