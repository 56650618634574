import { useTheme } from '@emotion/react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import config from '../../../config';
import Link from '../link';

const TreeNode = ({
  className = '',
  setCollapsed,
  collapsed,
  hoveredLink,
  setHoveredLink,
  url,
  title,
  items,
}) => {
  const isCollapsed = collapsed[url];

  const collapse = () => {
    setCollapsed(url);
  };

  const hasChildren = items.length !== 0;

  let location;

  if (typeof document != 'undefined') {
    location = document.location;
  }
  const active =
    location &&
    (location.pathname === url ||
      location.pathname === config.gatsby.pathPrefix + url ||
      (location.pathname === config.gatsby.pathPrefix && url === '/the-receptiviti-api'));

  const {
    colors: { text, highlightText },
  } = useTheme();

  return (
    <li className={`${className} item`} style={{ borderColor: text }}>
      {title && (
        <Link
          to={url}
          onMouseOver={() => setHoveredLink(url)}
          onMouseOut={() => setHoveredLink(undefined)}
        >
          <span style={{ color: active || hoveredLink === url ? highlightText : text }}>
            {title}
          </span>
          {!config.sidebar.frontLine && title && hasChildren ? (
            <button onClick={collapse} aria-label="collapse" className="collapser">
              {!isCollapsed ? (
                <KeyboardArrowDown style={{ color: text }} />
              ) : (
                <KeyboardArrowRight style={{ color: text }} />
              )}
            </button>
          ) : null}
        </Link>
      )}
      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              hoveredLink={hoveredLink}
              setHoveredLink={setHoveredLink}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default TreeNode;
