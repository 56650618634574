import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CodeExample from './CodeExample';
import CodeCopy from './CodeCopy';
import { languages, languageLabels } from './constants';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`code-tabpanel-${index}`}
      aria-labelledby={`code-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ overflowX: 'auto' }}>{children}</div>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `code-tab-${index}`,
    'aria-controls': `code-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1e1e1e',
    flexGrow: 1,
    margin: '32px 0',
    position: 'relative',
    '& header': {
      backgroundColor: '#3D454D',
      '& .MuiTabs-indicator': {
        backgroundColor: '#fff',
      },
    },
  },
}));

const CodeTabs = (props) => {
  const { endpoint, ...other } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="Code examples">
          {languages.map((lang, index) => (
            <Tab key={index} label={languageLabels[lang]} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      {languages.map((language, index) => (
        <TabPanel key={index} value={value} index={index}>
          <CodeExample endpoint={endpoint} language={language} />
        </TabPanel>
      ))}
      <CodeCopy endpoint={endpoint} language={languages[value]} />
    </div>
  );
};

CodeTabs.propTypes = {
  endpoint: PropTypes.any.isRequired,
};

export default CodeTabs;
